const appConfig = {
  appBaseUrl: process.env.NEXT_PUBLIC_BASE_URL ?? "",
  sitemapUrl: process.env.NEXT_PUBLIC_SITEMAP_URL ?? "",
  chatBotBaseUrl: process.env.NEXT_PUBLIC_CHATBOT_BASE_URL ?? "",
  chatBotToken: process.env.NEXT_PUBLIC_CHATBOT_TOKEN ?? "",
  recaptchSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? "",
  baseUrlCMS: process.env.NEXT_PUBLIC_BASE_URL_CMS ?? "",
  cmsToken: process.env.NEXT_PUBLIC_CMS_TOKEN ?? "",
  nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV ?? "",
  imageURL: process.env.NEXT_PUBLIC_IMAGE_URL ?? "",
  chatBotTenantId: process.env.NEXT_PUBLIC_CHATBOT_TENANT_ID ?? "",
  chatBotAppId: process.env.NEXT_PUBLIC_CHATBOT_APP_ID ?? "",
  hotjarSiteId: Number(process.env.NEXT_PUBLIC_SITE_ID ?? 0),
  hotjarVersion: Number(process.env.NEXT_PUBLIC_HOTJAR_VERSION ?? 6),
  microsoftClarityKey: process.env.NEXT_PUBLIC_MICROSOFT_CLARITY ?? "",
  googleGeocodeApi: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? " ",
  zohoSalesIqId: process.env.NEXT_PUBLIC_ZOHO_SIQ_WIDGET_ID ?? "",
  intercomId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? "",
  contactUsForm: process.env.NEXT_PUBLIC_CONTACT_US_FORM ?? "",
};

export default appConfig;
