import(/* webpackMode: "eager" */ "/app/apps/webelight-site/app/globals.scss");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/bluePhone.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/assets/envelope.svg");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/components/Footer/footer.scss");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/components/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/components/HotjarSnippet.tsx");
import(/* webpackMode: "eager" */ "/app/apps/webelight-site/layout/HomeLayout.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"utils/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--font-poppins\",\"preload\":true}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"utils/fonts.ts\",\"import\":\"Nunito\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--font-nunito\",\"preload\":true}],\"variableName\":\"nunito\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"utils/fonts.ts\",\"import\":\"Shadows_Into_Light\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--font-shadow-into-light\",\"preload\":true}],\"variableName\":\"shadowIntoLight\"}")