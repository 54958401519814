"use client";
import Script from "next/script";
import appConfig from "@webelight-site/config/app.config";

export const HotJarSnippet = () => {
  if (!process.env.NEXT_PUBLIC_SITE_ID) return null;

  return (
    <div>
      <Script id="hotjar-snippet">
        {`(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${appConfig.hotjarSiteId},hjsv:${appConfig.hotjarVersion}};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>
    </div>
  );
};
