import { useEffect, useMemo, useState } from "react";

const smallScreen = 767;
const mediumScreen = 768;
const largeScreen = 1024;

export const useDeviceDetect = () => {
  const [width, setWidth] = useState<number>();

  const handleWindowSizeChange = () => {
    if (window) {
      setWidth(window.innerWidth);
    }
  };
  const getDevice = useMemo(() => {
    if (width) {
      if (width <= smallScreen) {
        return {
          isMobile: true,
          isTablet: true,
          isDesktop: false,
          isTabletOnly: false,
          isMobileLandscape: false,
          deviceWidth: width,
        };
      }

      if (
        window?.matchMedia("(orientation: landscape)").matches &&
        width < largeScreen
      ) {
        return {
          isMobileLandscape: true,
          isMobile: false,
          isTablet: false,
          isDesktop: false,
          isTabletOnly: false,
          deviceWidth: width,
        };
      }

      if (width >= mediumScreen && width < largeScreen) {
        return {
          isMobile: false,
          isTablet: true,
          isDesktop: false,
          isTabletOnly: true,
          isMobileLandscape: false,
          deviceWidth: width,
        };
      }
    }

    return {
      isMobile: false,
      isTablet: false,
      isDesktop: true,
      isTabletOnly: false,
      isMobileLandscape: false,
      deviceWidth: width,
    };
  }, [width]);

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowSizeChange);
    }

    return () => {
      if (window) {
        window.removeEventListener("resize", handleWindowSizeChange);
      }
    };
  }, []);

  return getDevice;
};
