export const IndustryList = [
  {
    title: "Fintech",
    slug: "fintech-software-development",
  },
  {
    title: "Healthcare",
    slug: "healthcare-software-development",
  },
  {
    title: "Retail And Ecommerce",
    slug: "ecommerce-software-development",
  },
  {
    title: "Travel And Hospitality",
    slug: "travel-and-hospitality-app-development",
  },
  {
    title: "Real Estate",
    slug: "real-estate-software-development",
  },
  {
    title: "Energy And Utility",
    slug: "energy-and-utility-software-development",
  },
  {
    title: "Logistics",
    slug: "logistics-software-development",
  },
  {
    title: "E-Learning",
    slug: "elearning-software-development",
  },
];

export const HireDeveloperList = [
  {
    title: "Full Stack Developer",
    slug: "hire-full-stack-developer",
  },
];
