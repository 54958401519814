export const routes = {
  HOME: "/",
  ABOUT_US: "/about-us",
  SERVICES: "/services",
  CAREER: "/career",
  BLOGS: "/blog",
  CONTACT_US: "/contact-us",
  PORTFOLIO: "/portfolio",
  CUSTOM_SOFTWARE_DEVELOPMENT: "/services/custom-software-development",
  MOBILE_APP_DEVELOPMENT: "/services/mobile-app-development",
  DATA_AND_ARTIFICIAL_INTELLIGENCE:
    "/services/data-and-artificial-intelligence",
  DIGITAL_MARKETING_STRATEGY: "/services/digital-marketing-strategy",
  CLOUD_COMPUTING_AND_SECURITY: "/services/cloud-computing-and-security",
  BLOCKCHAIN: "/services/blockchain",
  UI_UX_DESIGN: "/services/ui-ux-design",
  ADVANCE_AUTOMATION: "/services/advanced-automation",
  SPECIFIC_BLOG: (slug: string) => `/blog/${slug}`,
  SCHEDULE_CALL: "#schedule-a-call",
  HIRE_NOW: "#vision",
  INDUSTRIES_FORM: "#industriesCallToAction",
  INDUSTRIES: (slug: string) => `/industries/${slug}`,
  FINTECH_SOFTWARE_DEVELOPER: "/industries/fintech-software-development",
  HIRE_FULL_STACK_DEVELOPER: "hire-full-stack-developer",
  HIRE_DEVELOPER: (slug: string) => `/${slug}`,
};
