/* eslint-disable max-lines */
"use client";

import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import ClickAwayListener from "react-click-away-listener";
import { ClientLink } from "@webelight-site/components/ClientLink";
import { routes } from "@webelight-site/utils/routes";
import {
  iconHeight,
  iconWidth,
  mediumHeight,
  mediumWidth,
} from "@webelight-site/constants/image-dimensions";
import { mobileMenuItems } from "@webelight-site/constants/menu";
import webelightLogo from "@webelight-site/public/logo-white.svg";
import menuBar from "@webelight-site/assets/menu-bar.svg";
import { MobileMenuList } from "./MobileMenuList";
import "./styles/header.scss";

const maxScrollPosition = 50;

const ServiceDropdown = dynamic(() =>
  import("./ServiceDropdown").then((mod) => mod.ServiceDropdown),
);

const HireDeveloperDropdown = dynamic(() =>
  import("./HireDeveloperDropdown").then((mod) => mod.HireDeveloperDropdown),
);

const IndustriesDropdown = dynamic(() =>
  import("./IndustriesDropdown").then((mod) => mod.IndustriesDropdown),
);

export interface MobileMenuItem {
  id: number;
  title: string;
  path: string;
  hasSubMenu: boolean;
  isVisible: boolean;
  hasIndustryMenu?: boolean;
  hasHireDevMenu?: boolean;
}

export const Header = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isIndustryMenuOpen, setIsIndustryMenuOpen] = useState(false);
  const [isHireDevMenuOpen, setIsHireDevMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isVisible =
        prevScrollPos > currentScrollPos ||
        currentScrollPos < maxScrollPosition;

      setPrevScrollPos(currentScrollPos);
      setVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const toggleMobileMenu = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  const handleOpenDropdown = () => {
    setIsServicesDropdownOpen(true);
  };

  const handleCloseDropdown = () => {
    setIsServicesDropdownOpen(false);
  };

  const handleCloseMobileList = () => setIsMobileMenu(false);

  const handleOpenHireDevMenu = () => {
    setIsHireDevMenuOpen(true);
  };

  const handleCloseHireDevMenu = () => {
    setIsHireDevMenuOpen(false);
  };

  const handleOpenIndustryMenu = () => {
    setIsIndustryMenuOpen(true);
  };

  const handleCloseIndustryMenu = () => {
    setIsIndustryMenuOpen(false);
  };

  const renderHeaderMenu = (mobileMenuItem: MobileMenuItem) => {
    const {
      id,
      title,
      path,
      hasSubMenu,
      isVisible,
      hasHireDevMenu,
      hasIndustryMenu,
    } = mobileMenuItem;

    if (hasSubMenu) {
      return (
        <ClickAwayListener
          key={id}
          onClickAway={() => setIsServicesDropdownOpen(false)}
        >
          <li
            onMouseOver={handleOpenDropdown}
            onMouseLeave={handleCloseDropdown}
          >
            <ClientLink
              href={path}
              className="hover:wb-text-primary wb-font-medium "
            >
              {title}
            </ClientLink>
            {isServicesDropdownOpen && (
              <ServiceDropdown
                isDropdownOpen={isServicesDropdownOpen}
                handleShowDropdown={handleOpenDropdown}
              />
            )}
          </li>
        </ClickAwayListener>
      );
    } else if (hasIndustryMenu) {
      return (
        <ClickAwayListener
          key={id}
          onClickAway={() => setIsIndustryMenuOpen(false)}
        >
          <button
            onMouseOver={handleOpenIndustryMenu}
            onMouseLeave={handleCloseIndustryMenu}
            onFocus={handleOpenIndustryMenu}
            className="industry-list-button"
          >
            <ClientLink
              href={path}
              className="hover:wb-text-primary wb-font-normal"
            >
              {title}
            </ClientLink>
            {isIndustryMenuOpen && (
              <IndustriesDropdown
                isDropdownOpen={isIndustryMenuOpen}
                handleShowDropdown={handleOpenIndustryMenu}
              />
            )}
          </button>
        </ClickAwayListener>
      );
    } else if (hasHireDevMenu) {
      return (
        <ClickAwayListener
          key={id}
          onClickAway={() => setIsHireDevMenuOpen(false)}
        >
          <button
            onMouseOver={handleOpenHireDevMenu}
            onMouseLeave={handleCloseHireDevMenu}
            onFocus={handleOpenHireDevMenu}
            className="industry-list-button"
          >
            <ClientLink
              href={path}
              className="hover:wb-text-primary wb-font-normal"
            >
              {title}
            </ClientLink>
            {isHireDevMenuOpen && (
              <HireDeveloperDropdown
                isDropdownOpen={isHireDevMenuOpen}
                handleShowDropdown={handleOpenHireDevMenu}
              />
            )}
          </button>
        </ClickAwayListener>
      );
    } else {
      return (
        <li key={id} className={`${isVisible ? "wb-flex" : "!wb-hidden"}`}>
          <ClientLink
            href={path}
            className="hover:wb-text-primary wb-font-normal "
          >
            {title}
          </ClientLink>
        </li>
      );
    }
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setScrolling(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="header-wrapper"
      className={`font-poppins header-main-container ${
        visible ? "" : "hidden"
      } ${scrolling ? "wb-bg-primary-dark" : ""} `}
    >
      <div className="header-body-container my-container wb-mx-auto">
        <div className="header-body-sub-container">
          <div className="wb-flex wb-grow wb-items-center wb-self-stretch wb-max-h-full wb-justify-between wb-font-medium">
            <div className="header-logo-block">
              <Link href={routes.HOME}>
                <Image
                  alt="Webelight"
                  width={mediumWidth}
                  height={mediumHeight}
                  src={webelightLogo}
                  priority
                  className="wb-w-[189px] wb-h-[40px] md:wb-w-[162px] md:wb-h-[34px]"
                />
              </Link>
            </div>
            <div className="menu-list-container flex-center">
              <ClickAwayListener onClickAway={handleCloseMobileList}>
                <div className="mobile-menu-dropdown">
                  <button
                    onClick={toggleMobileMenu}
                    className="wb-h-full wb-w-full flex-center"
                  >
                    <Image
                      src={menuBar}
                      alt="menu-bar"
                      width={iconWidth}
                      height={iconHeight}
                    />
                  </button>
                  <MobileMenuList
                    isMobileMenuVisible={isMobileMenu}
                    handleMobileMenuClose={handleCloseMobileList}
                  />
                </div>
              </ClickAwayListener>

              <div className="desktop-menu-dropdown">
                <ul className="menu-list" id="mainNav1">
                  {mobileMenuItems.map((mobileMenuItem: MobileMenuItem) =>
                    renderHeaderMenu(mobileMenuItem),
                  )}
                </ul>
              </div>
            </div>
            <div className="contact-us-btn-block">
              <Link href={routes.CONTACT_US} className="contact-us-button">
                <button>Get Started</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
