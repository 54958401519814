"use client";

import { ReactNode } from "react";
import { AnimatePresence } from "framer-motion";
import { useDeviceDetect } from "@webelight-site/components/Header/hooks/useDeviceDetect";

export const HomeLayout = ({ children }: { children: ReactNode }) => {
  const { isMobile } = useDeviceDetect();

  return (
    <AnimatePresence>
      <main
        role="main"
        className={`main font-poppins ${isMobile ? "wb-overflow-hidden" : ""}`}
      >
        {children}
      </main>
    </AnimatePresence>
  );
};
