"use client";

import { useState } from "react";
import Image from "next/image";
import {
  angleDownHeight,
  angleDownWidth,
  triangleDownHeight,
  triangleDownWidth,
} from "@webelight-site/constants/image-dimensions";
import { mobileMenuItems } from "@webelight-site/constants/menu";
import { IndustryList, HireDeveloperList } from "./constants";
import { ClientLink } from "@webelight-site/components/ClientLink";
import { MobileMenuItem } from "./Header";
import { routes } from "@webelight-site/utils/routes";
import angleDown from "@webelight-site/assets/triangle-down.svg";
import { ourHomeServices } from "@webelight-site/constants/our-services";

import "./styles/mobileMenuList.scss";

interface Props {
  isMobileMenuVisible: boolean;
  handleMobileMenuClose: () => void;
}

export const MobileMenuList = ({
  isMobileMenuVisible,
  handleMobileMenuClose,
}: Props) => {
  const [isOpenServicesDropdown, setIsOpenServicesDropdown] = useState(false);
  const [isOpenIndustryMenuDropdown, setIsOpenIndustryMenuDropdown] =
    useState(false);
  const [isOpenHireDevMenuDropdown, setIsOpenHireDevMenuDropdown] =
    useState(false);

  const toggleServiceDropdown = () => {
    setIsOpenServicesDropdown(!isOpenServicesDropdown);
  };

  const toggleIndustryMenuDropdown = () => {
    setIsOpenIndustryMenuDropdown(!isOpenIndustryMenuDropdown);
  };
  const toggleHireDevMenuDropdown = () => {
    setIsOpenHireDevMenuDropdown(!isOpenHireDevMenuDropdown);
  };

  const renderHeaderMenu = (mobileMenuItem: MobileMenuItem) => {
    const { id, title, path, hasSubMenu, hasIndustryMenu, hasHireDevMenu } =
      mobileMenuItem;

    if (hasSubMenu) {
      return (
        <li key={id} className="menu-item">
          <div className="wb-flex wb-items-center">
            <button className="" onClick={toggleServiceDropdown}>
              <ClientLink
                href={path}
                className="wb-justify-between menu-item-title"
              >
                {title}
                <div className="wb-pl-3  wb-w-10 wb-flex wb-items-center wb-justify-center">
                  <Image
                    src={angleDown}
                    alt="angle-down"
                    width={triangleDownWidth}
                    height={triangleDownHeight}
                  />
                </div>
              </ClientLink>
            </button>
          </div>

          {isOpenServicesDropdown && (
            <ul className="sub-menu-list">
              <li>
                <button
                  onClick={handleMobileMenuClose}
                  className="wb-w-full wb-text-start"
                >
                  <ClientLink href={"/services/discovery-phase-services"}>
                    Discovery Phases Service
                  </ClientLink>
                </button>
              </li>
              {ourHomeServices.map(
                ({ id: serviceId, url, title: servicesTitle }) => (
                  <li key={serviceId}>
                    <button
                      onClick={handleMobileMenuClose}
                      className="wb-w-full wb-text-start"
                    >
                      <ClientLink href={url}>{servicesTitle}</ClientLink>
                    </button>
                  </li>
                ),
              )}
            </ul>
          )}
        </li>
      );
    } else if (hasIndustryMenu) {
      return (
        <li key={id} className="menu-item">
          <button onClick={toggleIndustryMenuDropdown} className="wb-w-full">
            <div className="wb-flex wb-p-2 menu-item-title">
              {title}
              <div className="wb-pl-3  wb-w-10 wb-flex wb-items-center wb-justify-center menu-item-title">
                <Image
                  src={angleDown}
                  alt="angle-down"
                  width={triangleDownWidth}
                  height={triangleDownHeight}
                />
              </div>
            </div>
          </button>

          {isOpenIndustryMenuDropdown && (
            <ul className="sub-menu-list">
              {IndustryList.map(({ slug, title: industryType }) => (
                <li key={industryType}>
                  <button
                    onClick={handleMobileMenuClose}
                    className="wb-w-full wb-text-start"
                  >
                    <ClientLink href={routes.INDUSTRIES(slug)}>
                      {industryType}
                    </ClientLink>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    } else if (hasHireDevMenu) {
      return (
        <li key={id} className="menu-item">
          <button onClick={toggleHireDevMenuDropdown} className="wb-w-full">
            <div className=" wb-flex wb-p-2 wb-justify-between">
              {title}
              <div className="wb-pr-2 wb-flex wb-items-center wb-w-10 wb-justify-center wb-relative wb-z-10">
                <Image
                  src={angleDown}
                  alt="angle-down"
                  width={angleDownWidth}
                  height={angleDownHeight}
                />
              </div>
            </div>
          </button>

          {isOpenHireDevMenuDropdown && (
            <ul className="sub-menu-list">
              {HireDeveloperList.map(({ slug, title: industryType }) => (
                <li key={industryType}>
                  <button
                    onClick={handleMobileMenuClose}
                    className="wb-w-full wb-text-start"
                  >
                    <ClientLink
                      href={routes.HIRE_DEVELOPER(slug)}
                      className="menu-item-title"
                    >
                      {industryType}
                    </ClientLink>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    } else {
      return (
        <li key={id} className="menu-item">
          <button onClick={handleMobileMenuClose} className="wb-w-full">
            <ClientLink href={path} className="menu-item-title">
              {title}
            </ClientLink>
          </button>
        </li>
      );
    }
  };

  return (
    <div
      className={`header-tab-popup wb-overflow-auto wb-transition-all wb-duration-500 wb-h-max ${
        isMobileMenuVisible ? "wb-max-h-[656px]" : "wb-max-h-0"
      }`}
      id="mobile-header"
    >
      <div className="btn-wrapper my-container">
        <ul className="tab-list" id="mobile-nav">
          {mobileMenuItems.map((mobileMenuItem: MobileMenuItem) =>
            renderHeaderMenu(mobileMenuItem),
          )}
        </ul>
      </div>
    </div>
  );
};
