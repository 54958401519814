import advanceAutomationService from "@webelight-site/public/img/homepage/services/services/crm.png";
import blockChainService from "@webelight-site/public/img/homepage/services/services/block.png";
import cloudComputingService from "@webelight-site/public/img/homepage/services/services/cloud.png";
import customsoftwaredevelopmentService from "@webelight-site/public/img/homepage/services/services/customsoftwaredevelopment.png";
import dataAndAiService from "@webelight-site/public/img/homepage/services/services/data.png";
import mobileapplicationService from "@webelight-site/public/img/homepage/services/services/mobileapp.png";
import uiuxdesignService from "@webelight-site/public/img/og-images/uiuxdesign.png";
import marketingService from "@webelight-site/public/img/og-images/brandingmarketing.png";

export const ourHomeServices = [
  {
    id: 1,
    title: "Custom Software Development",
    descriptions:
      "Tailored software solutions designed to meet unique business requirements, enhancing efficiency and fostering organizational growth.",
    icon: "/img/homepage/whatwecan/customsoftware.svg",
    animation: "fadeInLeftShorter",
    url: "/services/custom-software-development",
    variant: { initial: { x: 100, opacity: 0 }, final: { x: 0, opacity: 1 } },
    imagePath: customsoftwaredevelopmentService,
    gridClass: "card-1",
    cardTitle: "Custom Software \n Development",
    slug: "/services/custom-software-development",
  },
  {
    id: 2,
    title: "Mobile App Development",
    descriptions:
      "Innovative mobile app creation, delivering user-friendly experiences that align with client objectives and industry standards.",
    icon: "/img/homepage/whatwecan/mobileapp.svg",
    animation: "fadeInUpShorter",
    url: "/services/mobile-app-development",
    variant: { initial: { y: 100, opacity: 0 }, final: { y: 0, opacity: 1 } },
    imagePath: mobileapplicationService,
    gridClass: "card-2",
    cardTitle: "Mobile App \n Development",
    slug: "/services/mobile-app-development",
  },
  {
    id: 3,
    title: "Data and Artificial Intelligence",
    descriptions:
      "Harnessing data insights and AI capabilities to empower businesses, drive informed decisions, and optimize operational processes effectively.",
    icon: "/img/homepage/whatwecan/dataandartificial.svg",
    animation: "fadeInRightShorter",
    url: "/services/data-and-artificial-intelligence",
    variant: { initial: { x: -100, opacity: 0 }, final: { x: 0, opacity: 1 } },
    imagePath: dataAndAiService,
    gridClass: "card-3",
    cardTitle: "Data and Artificial Intelligence",
    slug: "/services/data-and-artificial-intelligence",
  },
  {
    id: 4,
    title: "Blockchain",
    descriptions:
      "Implementing secure and transparent blockchain solutions for trustworthy and decentralized transactions, ensuring data integrity and reliability.",
    icon: "/img/homepage/whatwecan/blockchain.svg",
    animation: "fadeInLeftShorter",
    url: "/services/blockchain",
    variant: { initial: { x: 100, opacity: 0 }, final: { x: 0, opacity: 1 } },
    imagePath: blockChainService,
    gridClass: "card-4",
    cardTitle: "Blockchain \n Development",
    slug: "/services/blockchain",
  },
  {
    id: 5,
    title: "Advanced Automation",
    descriptions:
      "Rigorous quality assurance practices, including advanced automation techniques, to ensure software reliability, functionality, and performance under diverse conditions.",
    icon: "/img/homepage/whatwecan/advanceautomation.svg",
    animation: "fadeInUpShorter",
    url: "/services/advanced-automation",
    variant: { initial: { y: 100, opacity: 0 }, final: { y: 0, opacity: 1 } },
    imagePath: advanceAutomationService,
    gridClass: "card-5",
    cardTitle: "Advanced Automation",
    slug: "/services/advanced-automation",
  },
  {
    id: 6,
    title: "Cloud Computing and Security",
    descriptions:
      "Scalable cloud solutions with robust security measures, ensuring data confidentiality, availability, and protection against potential cyber threats.",
    icon: "/img/homepage/whatwecan/cloudcomputing.svg",
    animation: "fadeInRightShorter",
    url: "/services/cloud-computing-and-security",
    variant: { initial: { x: -100, opacity: 0 }, final: { x: 0, opacity: 1 } },
    imagePath: cloudComputingService,
    gridClass: "card-6",
    cardTitle: "Cloud Computing And Security",
    slug: "/services/cloud-computing-and-security",
  },
  {
    id: 7,
    title: "UI/UX Design",
    descriptions:
      "Crafting intuitive and visually appealing user interfaces to enhance user experience, fostering engagement and satisfaction across digital platforms.",
    icon: "/img/homepage/whatwecan/cloudcomputing.svg",
    animation: "fadeInRightShorter",
    url: "/services/ui-ux-design",
    imagePath: uiuxdesignService,
    slug: "/services/ui-ux-design",
  },
  {
    id: 8,
    title: "Digital Marketing & Strategy",
    descriptions:
      "Strategic digital marketing campaigns tailored to client goals, leveraging online channels to maximize brand visibility, reach target audiences, and drive growth.",
    icon: "/img/homepage/whatwecan/cloudcomputing.svg",
    animation: "fadeInRightShorter",
    url: "/services/digital-marketing-strategy",
    imagePath: marketingService,
    slug: "/services/digital-marketing-strategy",
  },
];
