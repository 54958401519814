import { routes } from "../utils/routes";

export const mobileMenuItems = [
  {
    id: 1,
    title: "Home",
    path: routes.HOME,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 2,
    title: "About Us",
    path: routes.ABOUT_US,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 3,
    title: "Services",
    path: routes.SERVICES,
    hasSubMenu: true,
    isVisible: true,
  },
  {
    id: 4,
    title: "Industries",
    path: routes.FINTECH_SOFTWARE_DEVELOPER,
    hasSubMenu: false,
    isVisible: true,
    hasIndustryMenu: true,
  },
  //need this code in future
  // {
  //   id: 5,
  //   title: "Hire",
  //   path: routes.HIRE_FULL_STACK_DEVELOPER,
  //   hasSubMenu: false,
  //   isVisible: true,
  //   hasHireDevMenu: true,
  // },
  {
    id: 6,
    title: "Portfolio",
    path: routes.PORTFOLIO,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 7,
    title: "Careers",
    path: routes.CAREER,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 8,
    title: "Blog",
    path: routes.BLOGS,
    hasSubMenu: false,
    isVisible: true,
  },
  {
    id: 9,
    title: "Contact Us",
    path: routes.CONTACT_US,
    hasSubMenu: false,
    isVisible: false,
  },
];
