export const verySmallWidth = 27;
export const extraSmallWidth = 30;
export const smallWidth = 63;
export const mSmallWidth = 70;
export const extraNormalWidth = 80;
export const normalWidth = 100;
export const extraMediumWidth = 145;
export const mediumWidth = 200;
export const largeWidth = 288;
export const mLargeWidth = 347;
export const extraLargeWidth = 340;
export const exLargeWidth = 361;
export const blogImageWidth = 392;
export const xxlWidth = 598;
export const verySmallHeight = 27;
export const extraSmallHeight = 30;
export const mSmallHeight = 60;
export const smallHeight = 63;
export const normalHeight = 80;
export const xNormalHeight = 100;
export const extraMediumHeight = 145;
export const mHeight = 200;
export const blogImageHeight = 204;
export const mediumHeight = 216;
export const largeHeight = 242;
export const mLargeHeight = 245;
export const mlLargeHeight = 347;
export const extraLargeHeight = 340;
export const exLargeHeight = 362;
export const xxlHeight = 485;
export const imageQuality = 100;
export const iconWidth = 27;
export const iconHeight = 27;
export const checkMarkImageWidth = 30;
export const checkMarkImageHeight = 30;
export const angleDownWidth = 50;
export const angleDownHeight = 50;
export const triangleDownWidth = 12;
export const triangleDownHeight = 12;
export const servicesImageWidth = 250;
export const servicesImageHeight = 380;
export const smallImageWidth = 70;
export const smallImageHeight = 60;
